import { createContext, useContext } from "react";

const StylesContext = createContext();

export default function StylesProvider({ children }) {

    const sxStyles = {
            boxBodyContent: {
                padding: 1,
                flex: 'auto',
            },
            boxBodyHeader: {
                flexBasis: '100%',
                mb: 2,
            },
            boxFileUploadProgress: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            },
            boxFileUploadProgressBar:{
                width: '100%',
                minWidth: 30,
                marginLeft: 5,
                marginRight: 3,
                },
            boxFlexColAlignItemsCenter: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            boxFlexColJustifyCenter: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            boxFlexRowAlignItemsCenter: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
            },
            boxFlexRowAlignItemsFlexStart: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
            },
            boxFlexRowCenterAll: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',   
                justifyContent: 'center',
            },
            boxFlexRowJustifyCenter: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
            },
            boxFlexRowJustifySpaceEvenly: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
            },
            boxBodyHeader2Col: {
                flexBasis: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
            },
            boxFilterField: {
                marginTop: 0,
                marginBottom: 1,
                marginRight: .5,
                width: 200,
            },
            boxFlex: {
                display: 'flex',
            },
            boxMainContent: {
                padding: 1,
            },
            box1PxBorderFitContent: {
                border: 1,
                padding: 1,
                width: 'fit-content',
            },
            buttonForm: {
                //color element controls button text but not button bkgd
            },
            cardContentMenuCard: {
                textAlign: 'center',
            },
            cardRootMenuCard: {
                minWidth: 250,
                display: 'inline-flex',
                margin: 1,
            },
            faceTileTopRow: {
                display: 'flex',
                whiteSpace: 'nowrap',
                alignItems: 'center',
            },
            faceTileBottomRow: {
                display: 'flex',
                justifyContent: 'flex-start',
            },
            flexBreak: {
                flexBasis: '100%',
                height: 0,
            },
            flexColJustifySpaceBetween: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            },
            flexRowAlignCenter: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
            },
            flexRowJustifyFlexStart: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
            },
            formField: {
                mt: 1,
                mb: 1,
                ml: 1,
                mr: 1,
                minWidth: 25,
                maxWidth: 250,
            },
            iconMenuCard: {
                color: 'primary.main',
                marginBottom: 2,
                fontSize: 120
            },
            iconMenuCardDisabled: {
                color: 'grey',
                marginBottom: 2,
                fontSize: 120
            },
            labelValue: {
                display: 'flex',
                alignItems: 'baseline',
            },
            paperBody: {
                marginBottom: 2,
                p: 2,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-around',
            },
            tableCellHead: {
                color: 'primary.main',
                textAlign: 'center',
            },
            textPageTitle: {
                color: 'primary.main',
                fontSize: 20,
                fontWeight: 'bold',
            },
            textInstructions: {
                typography: 'body2',
                pb: 1,
            },
        };
        

    return(
        <StylesContext.Provider value={sxStyles}>
            {children}
        </StylesContext.Provider>
    );
}

export function useStyles() {
    return useContext(StylesContext);
}