import { createContext, useContext, useEffect, useState } from "react";
import { useApi } from "./ApiProvider";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState();
    const api = useApi();

    useEffect(() => {
        (async () => {
            if (api.isAuthenticated()) {
                const response = await api.get('/me');
                setUser(response.ok ? response.body : null);
            }
            else {
                setUser(null);
            }
        })();
    },[api])

    const login = async (username, password) => {
        const result = await api.login(username, password);
        if (result === 'ok') {
            const response = await api.get('/me');
            setUser(response.ok ? response.body : null);
            return response.ok;
        };
        return result;
    };

    const logout = async () => {
        await api.logout();
        setUser(null);
    };

    const resetPassword = async (email) => {
        const response = await api.post('/tokens/reset',{email: email});
        return {
            ok: response.ok,
            status: response.status,
        };
    };

    return (
        <UserContext.Provider value={{ user, setUser, login, logout, resetPassword }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;

export function useUser() {
    return useContext(UserContext);
}

