export function validateStringLength (theString, minLength, maxLength=null) {
    //returns an object of structure { valid: <boolean>, message: string }
    if (!theString || theString.length < minLength) {
        return ({ valid: false, message: 'Must be at least ' + minLength + ' characters' })
    }
    if (maxLength && theString.length > maxLength) {
        return ({ valid: false, message: 'Must be between ' + minLength + ' and ' + maxLength + 'characters' })
    }
    return { valid:true }
}

export function validateEmail (email) {
    //returns an object of structure { valid: <boolean>, message: string }
    if (validateStringLength(email, 6).valid === true) {
        if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return { valid: true }
        }
        return {valid: false, message: 'Invalid email address'}
    }
    return {valid: false, message: null}
}

