import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Container } from "@mui/material";

import DashboardPage from "./pages/DashboardPage";
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage"
import ResetPage from "./pages/ResetPage"
import IodUnsubscribePage from "./pages/IodUnsubscribePage";
import ApiProvider from "./contexts/ApiProvider";
import ApiCallsProvider from "./contexts/ApiCallsProvider";
import UserProvider from "./contexts/UserProvider";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import FlashProvider from "./contexts/FlashProvider";
import TabsProvider from "./contexts/TabsProvider";
import StylesProvider from "./contexts/StylesProvider";
import ImageGroupProvider from "./contexts/ImageGroupProvider";
import NavProvider from "./contexts/NavProvider";

import Header from "./components/Header";
import ProgressBarIndeterminate from "./components/ProgressBarIndeterminate";

export default function App() {

  const PasswordMgrPage = lazy(() => import('./pages/PasswordMgrPage'));
  const ActivitiesMgrPage = lazy(() => import('./pages/ActivitiesMgrPage'));
  const LocationsMgrPage = lazy(() => import('./pages/LocationsMgrPage'));
  const ProgramsMgrPage = lazy(() => import('./pages/ProgramsMgrPage'));
  const ProgramDetailPage = lazy(() => import('./pages/ProgramDetailPage'))
  const ProgramPeoplePage = lazy(() => import('./pages/ProgramPeoplePage'))
  const ParticipantsMgrPage = lazy(() => import('./pages/ParticipantsMgrPage'));
  const ImageOfTheDayMgrPage = lazy(() => import('./pages/ImageOfTheDayMgrPage'));
  const UserMgrPage = lazy(() => import('./pages/UserMgrPage'));
  const ImportPage = lazy(() => import('./pages/ImportPage'));
  const ImagesPage = lazy(() => import('./pages/ImagesPage'));
  const ImagePage = lazy(() => import('./pages/ImagePage'));
  const ConfirmPage = lazy(() => import('./pages/ConfirmPage'));
  const FacesConfirmPage = lazy(() => import('./pages/FacesConfirmPage'));
  const SearchPage = lazy(() => import('./pages/SearchPage'));
  const RolesMgrPage = lazy(() => import('./pages/RolesMgrPage'));
  const CollectionsPage = lazy(() => import('./pages/CollectionsPage'));
  const CollectionDetailPage = lazy(() => import('./pages/CollectionDetailPage'));

  return (
    <Container className="App">
      <BrowserRouter>
        <NavProvider>
          <StylesProvider>
            <FlashProvider>
              <ApiProvider>
                <ApiCallsProvider>
                  <UserProvider>
                    <TabsProvider>
                      <ImageGroupProvider>
                        <Header />
                        <Routes>
                          <Route path="/login" element={<PublicRoute><LoginPage /></PublicRoute>} />
                          <Route path="/reset" element={<PublicRoute><ResetPage /></PublicRoute>} />
                          <Route path="/iodunsubscribe/:hash" element={<PublicRoute><IodUnsubscribePage /></PublicRoute>} />
                          <Route path="*" element={
                            <PrivateRoute>
                              <Routes>
                                <Route path="*" element={<Navigate to="/" />} />
                                <Route path="/" element={<DashboardPage />} />
                                <Route path="/settings" element={<SettingsPage />} />
                                <Route path="/password" element={<Suspense fallback={<ProgressBarIndeterminate />}><PasswordMgrPage /></Suspense>} />
                                <Route path="/activities" element={<Suspense fallback={<ProgressBarIndeterminate />}><ActivitiesMgrPage /></Suspense>} />
                                <Route path="/locations" element={<Suspense fallback={<ProgressBarIndeterminate />}><LocationsMgrPage /></Suspense>} />
                                <Route path="/people" element={<Suspense fallback={<ProgressBarIndeterminate />}><ParticipantsMgrPage /></Suspense>} />
                                <Route path="/programs" element={<Suspense fallback={<ProgressBarIndeterminate />}><ProgramsMgrPage /></Suspense>} />
                                <Route path="/programs/:id" element={<Suspense fallback={<ProgressBarIndeterminate />}><ProgramDetailPage /></Suspense>} />
                                <Route path="/programs/:id/people" element={<Suspense fallback={<ProgressBarIndeterminate />}><ProgramPeoplePage /></Suspense>} />
                                <Route path="/imageoftheday" element={<Suspense fallback={<ProgressBarIndeterminate />}><ImageOfTheDayMgrPage /></Suspense>} />
                                <Route path="/users" element={<Suspense fallback={<ProgressBarIndeterminate />}><UserMgrPage /></Suspense>} />
                                <Route path="/import" element={<Suspense fallback={<ProgressBarIndeterminate />}><ImportPage /></Suspense>} />
                                <Route path="/images" element={<Suspense fallback={<ProgressBarIndeterminate />}><ImagesPage /></Suspense>} />
                                <Route path="/images/:id" element={<Suspense fallback={<ProgressBarIndeterminate />}><ImagePage /></Suspense>} />
                                <Route path="/images/confirm" element={<Suspense fallback={<ProgressBarIndeterminate />}><ConfirmPage /></Suspense>} />
                                <Route path="/images/confirm/faces" element={<Suspense fallback={<ProgressBarIndeterminate />}><FacesConfirmPage /></Suspense>} />
                                <Route path="/search" element={<Suspense fallback={<ProgressBarIndeterminate />}><SearchPage /></Suspense>} />
                                <Route path="/roles" element={<Suspense fallback={<ProgressBarIndeterminate />}><RolesMgrPage /></Suspense>} />
                                <Route path="/collections" element={<Suspense fallback={<ProgressBarIndeterminate />}><CollectionsPage /></Suspense>} />
                                <Route path="/collections/:id" element={<Suspense fallback={<ProgressBarIndeterminate />}><CollectionDetailPage /></Suspense>} />
                              </Routes>
                            </PrivateRoute>
                          } />
                        </Routes>
                      </ImageGroupProvider>
                    </TabsProvider>
                  </UserProvider>
                </ApiCallsProvider>
              </ApiProvider>
            </FlashProvider>
          </StylesProvider>
        </NavProvider>
      </BrowserRouter>
    </Container>
  );
}
