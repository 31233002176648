import { Box, Typography } from "@mui/material";
import { useStyles } from "../contexts/StylesProvider";


const PasswordRules = () => {
    const styles = useStyles();
    return (
        <Box sx={{ml: 2}}>
            <Typography sx={{...styles.textInstructions, pb: 0 }}>Passwords must meet the following requirements:</Typography>
            <Typography sx={{...styles.textInstructions, ml: 1, pb: 0}}>
                • 8-32 characters long<br />
                • contain at least one uppercase letter<br />
                • contain at least one lowercase letter<br />
                • contain at least one digit<br />
                • contain at least one special characters
            </Typography>
        </Box>
    );
};

export default PasswordRules;

export function validatePwStructure(password) {

    /* Password Strength Rules
        at least one digit
        at least one lowercase character
        at least one uppercase character
        at least one special character ?=.*[*.!@$%^&(){}[]:;<>,.?\/~_+-=|\]
        [8,32] characters long
    */

    const minLength = 8;
    const maxLength = 32;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasDigit = /[0-9]/;
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (password.length < minLength || password.length > maxLength) {
        return false;
    }
    if (!hasLowerCase.test(password)) {
        return false;
    }
    if (!hasUpperCase.test(password)) {
        return false;
    }
    if (!hasDigit.test(password)) {
        return false;
    }
    if (!hasSpecialChar.test(password)) {
        return false;
    }
    return true;
};