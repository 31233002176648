import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useStyles } from '../contexts/StylesProvider';
import CircularIndeterminate from './CircularIndeterminate';
import { useApiCalls } from '../contexts/ApiCallsProvider';
import { handleStandardAPIResponse } from '../utils/utils';
import { useFlash } from '../contexts/FlashProvider';


const ImageOfTheDay = () => {

/*
    On Load: 
        - get iod data from API - put in state
        - load source - put in state

    Render:
        if data and source null, 
            render spinner
        if error retrieving source, render nothing.
        else,
            render IOD

*/  
    const { 
        getFileDataUrl,
        getImageOfTheDay,
    } = useApiCalls();

    const flash = useFlash();
    const styles = useStyles();

    const [ iodData, setIodData ] = useState()
    const [ iodSource, setIodSource ] = useState(null)

    useEffect(() => {
        (async () => {
            if (iodData === undefined) {
                const result = await getImageOfTheDay();
                handleStandardAPIResponse(
                    result,
                    'An error occurred retrieving the Image of the Day',
                    flash,
                    'error',
                    setIodData,
                    404
                )
            };
            if (iodData !== null && iodData !== undefined && iodSource === null) {
                setIodSource(await getFileDataUrl(iodData.thumb_location));
            };
        })();
    },[iodData, iodSource, getFileDataUrl, getImageOfTheDay, flash])

    if (iodData === undefined || iodData === 404 || iodSource === 'error') return null;

    if (iodData !== null) {
        return (
            <Box sx = {{ ...styles.boxFlexColAlignItemsCenter, width: '400px' }}>
                <Typography color='primary' variant='h6'>Image of the Day, from {iodData.program.name + ' ' + iodData.program.year}</Typography>
                {iodSource !== null ? <img alt='IoD' src={iodSource} /> : <CircularIndeterminate />}
            </Box>
        );
    } else {
        return (
            <Box>
                {iodData !== 404 && <CircularIndeterminate />}
            </Box>
        );
    };

};

export default ImageOfTheDay;