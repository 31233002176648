export function createDataUrl(data) {
    return new Promise(
        function (resolve, reject) {
            let fr = new FileReader()
            fr.readAsDataURL(data)
            fr.addEventListener('loadend', (e) => {
                resolve (e.srcElement.result)
            })
        }
    )
};

export const cacheImageSource = async (id,variant,source=null) => {
    if (source==null) { //remove the cache item
        delete window[variant][id];
        return;
    }
    if (window[variant]===undefined) window[variant]={};
    window[variant][id] = source;
};

export const getCachedImageSource = (id,variant, checkOnly=false) => {
    return window[variant]===undefined ?
        undefined
        : id in window[variant] ?
            checkOnly ?
                true
                : window[variant][id]
            : undefined
};

export const deleteCachedImageSource = (id, variant) => {
    if (window[variant]) {
        delete window[variant][id];
    };
    return;
};

export const checkFacesConfirmed = (image) => {
    //circuit breaker
    if (image===undefined) return undefined;
    
    const defaultOutput = {
        anyConfirmed: false,
        allConfirmed: false,
        confirmedCount: 0,
        unconfirmedCount: 0,
    };
    if (image.faces) {
        let anyConfirmed=false;
        let allConfirmed=true;
        let confirmedCount, unconfirmedCount = 0;
        image.faces.map((face) => {
            anyConfirmed = anyConfirmed || face.confirmed;
            !face.confirmed ? allConfirmed=false : allConfirmed=!!allConfirmed;
            face.confirmed ? confirmedCount += 1 : unconfirmedCount += 1;
            return undefined;
        });
        return (
            {
                anyConfirmed: anyConfirmed,
                allConfirmed: allConfirmed,
                confirmedCount: confirmedCount,
                unconfirmedCount: unconfirmedCount,
            }
        );
    } else {
        return defaultOutput;
    };
};
