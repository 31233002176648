import { createContext, useContext } from "react";

const NavContext = createContext();

export default function NavProvider({ children }) {

    const navPaths = {
        viewImage: (id) => {return `/images/${id}?action=view`},
        editImage: (id) => {return `/images/${id}?action=edit`},
    };
        

    return(
        <NavContext.Provider value={navPaths}>
            {children}
        </NavContext.Provider>
    );
}

export function useNavPaths() {
    return useContext(NavContext);
}