import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import Body from "../components/Body";
import { useApiCalls } from "../contexts/ApiCallsProvider";
import ProgressBarIndeterminate from "../components/ProgressBarIndeterminate";
import { useStyles } from "../contexts/StylesProvider";

const IodUnsubscribePage = () => {

    const { hash } = useParams();
    const { postPublicIodUnsubscribe } = useApiCalls();
    const styles = useStyles();
    const [ processing, setProcessing ] = useState(true);
    const [ timer, setTimer ] = useState(3);

    useEffect(() => {
        (async () => {
            if (hash.length === 64) {
                postPublicIodUnsubscribe(hash);
                setProcessing(false);
            };
        })();
    },[hash, postPublicIodUnsubscribe])

    const startTimer = useCallback(
        () => {
            const foo = setInterval(() => {
                setTimer(timer-1);
                if (timer === -1) {
                    clearInterval(foo);
                    window.location.href='http://www.takumta.org';
                };
            },600);
    },[timer]);

    useEffect(() => {
        if (processing===false) {
            startTimer();
        };
    },[processing, startTimer]);

    return (
        <Body>
            <Box>
                <Typography sx={styles.textPageTitle}>Unsubscribe</Typography>
                {hash.length===64 && processing && <ProgressBarIndeterminate />}
                {hash.length !== 64 && processing && <Typography color='secondary' sx={{ mt:2 }}>Invalid request.</Typography>}
                {processing === false && 
                    <Box>
                        <Typography>Your request has been processed. Thank you.</Typography>
                        <Typography sx={{ mt:2 }}>You will now be redirected to the {process.env.REACT_APP_ORG_NAME} homepage.</Typography>
                    </Box>
                }
            </Box>
        </Body>
    );
};

export default IodUnsubscribePage;