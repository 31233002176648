import { createContext, useContext, useState } from "react";

export const TabsContext = createContext();

const TabsProvider = ({ children }) => {
    const [currentTab, setCurrentTab] = useState('/dashboard');

    const ensureTab = (tab) => {
        if (currentTab !== tab) {
            setCurrentTab(tab);
        }
    };

    return (
        <TabsContext.Provider value={{ currentTab, setCurrentTab, ensureTab }}>
            {children}
        </TabsContext.Provider>
    );
}

export function useEnsureTab() {
    return useContext(TabsContext).ensureTab;
}

export default TabsProvider;