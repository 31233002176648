import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";

import Body from "../components/Body";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import { useStyles } from '../contexts/StylesProvider';
import PasswordRules, { validatePwStructure } from "../components/PasswordRules";

const ResetPage = () => {

    const navigate = useNavigate();
    const api = useApi();
    const flash = useFlash();
    const styles = useStyles();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');

    const [formErrors, setFormErrors] = useState({});
    const [passwordValue, setPasswordValue] = useState('');
    const [password2Value, setPassword2Value] = useState('');

    const passwordField = useRef();
    const password2Field = useRef();
  
    useEffect(() => {
      if (!token) {
        navigate('/');
      }
      else {
        passwordField.current.focus();
      }
    }, [token, navigate]);
  
    const onSubmit = async (event) => {
        event.preventDefault();
        if (!validatePwStructure(passwordValue)) {
          setFormErrors({password: "Password does not meet security requirements"})
          return;
        };
        if (passwordValue !== password2Value) {
            setFormErrors({password2: "New passwords don't match."});
        }
        else {
          const response = await api.put('/tokens/reset', {
            token,
            new_password: passwordValue
          });
          if (response.ok) {
            setFormErrors({});
            flash('Your password has been reset.', 'success');
            navigate('/login');
          }
          else {
            /* if (response.body.errors.json.new_password) {
              setFormErrors(response.body.errors.json);
            }
            else { */
              flash(`Password could not be reset. Your reset link may have expired or you tried using a previous password. You may not reuse your ${process.env.REACT_APP_PASSWORD_NONREUSE_COUNT} previous passwords.`, 'error');
              //navigate('/reset-request');
            //}
          };
        };
      };
    
    const handlePasswordChange = (ev) => {
        setPasswordValue(ev.target.value);
    };

    const handlePassword2Change = (ev) => {
        setPassword2Value(ev.target.value);
    };

    return (
            <Body>
                <Box>
                    <Typography sx={styles.textPageTitle}>Reset Your Password</Typography>
                    <PasswordRules />
                    <Box id='bodyContentBox' sx={styles.bodyContentBox}>
                        <form onSubmit={onSubmit}>
                            <TextField
                                id="password-field"
                                label="Password"
                                inputRef={passwordField}
                                value={passwordValue}
                                onChange={handlePasswordChange}
                                variant="outlined"
                                type="password"
                                error={'password' in formErrors}
                                helperText={formErrors.password}
                                sx={{...styles.formField, minWidth: '250px'}}
                                />
                            <TextField
                                id="password2-field"
                                label="Repeat Password"
                                inputRef={password2Field}
                                value={password2Value}
                                onChange={handlePassword2Change}
                                variant="outlined"
                                type="password"
                                error={'password2' in formErrors}
                                helperText={formErrors.password2}
                                sx={{...styles.formField, minWidth: '250px'}}
                            />
                            <div sx={styles.flexBreak} />
                            <Button variant="contained" type="submit" sx={{ mt: 2, ml: 1 }}>Reset Password</Button>
                        </form>
                    </Box>
                </Box>
            </Body>
    );
};

export default ResetPage;